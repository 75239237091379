<template>
    <div v-show="teamInfo.squadData">
            <div class="nav-pill__wrapper mb-main container" >
                <!-- <div class="nav-pill selected" >Season 2020-2011</div> -->
            </div>
                <!-- <div class="team-info__overview-card" v-for="(o, index) in overviewList" :key="index">
                    <div class="team-info__overview-header">{{o.title}}</div>
                    <div class="team-info__overview-body"  v-for="(level2, index) in o.child" :key="index">
                        <div class="">{{level2.key}}</div>
                        <div class="">{{level2.value}}</div>
                    </div>
                </div> -->
                    
                <div class="team-info__overview-card">
                    <div class="team-info__overview-body__header">
                        <div class="team-info__overview-goal__player">
                            <div class="team-info__overview-goal__player-rank">#</div>
                            <div>{{$t('PLAYER')}}</div>                          
                        </div>
                        <div class="display-flex team-info__overview-goal__player-goal__title">
                            <div class="team-info__overview-goal__player-goal">{{$t('POSITION')}}</div>
                        </div>
                    </div>
                    <Navigation :id="level2.id" :category="'player'" :name="level2.name"  class="team-info__overview-body"   v-for="(level2, index) in teamInfo.squadData" :key="index">
                        <div class="team-info__overview-goal__player">
                            <div class="team-info__overview-goal__player-rank" v-if="level2.number===''">#</div>
                             <div class="team-info__overview-goal__player-rank" v-else>{{level2.number}}</div>
                             <Icon :category="'player'" :logoUrl=level2.logo  class="team-info__overview-goal__player-img"></Icon>
                            <div class="team-info__overview-goal__player-name">{{level2.name}}</div>
                        </div>
                        <div class="display-flex team-info__overview-goal__player-goal__title">
                            <div class="team-info__overview-goal__player-goal">{{$t(level2.pos)}}</div>                        
                        </div>                      
                    </Navigation>
                </div>
                    
        </div>
        <div class="no-data" v-show="!teamInfo.squadData">{{$t('NO_ROSTER')}}</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
  computed: {
        ...mapGetters([
            'teamInfo',
            'isMobile',
            'currentLocale',
            'currentSportType'
        ]),
    },
}
</script>

<style scoped>
.team-info__overview-goal__player-goal__title{
    flex: 0.5;
}
.team-info__overview-goal__player-goal{
    flex: 1;
    text-align:center;
}
</style>

